import React from "react";
import {
    tranBox,
    tranWrap,
    tranSection,
    dtFont
} from "./style.module.scss";
import ProjectColumns, { Column } from "../../Components/ProjectColumns";
import Player from "../../Components/Audio";
import Sound from "../../work/DalenaTran/assets/01.mp3"
// import { Link } from "react-router-dom";

export default function Transcript() {
    return (
        <ProjectColumns className={tranSection}>
            <Column className={tranWrap}>
                <h3 className={dtFont}>Voice Over Audio & Transcript</h3>
                <br/>
                <Player
                    url={Sound}
                />
                <br />
                <div className={tranBox}>
                    <p>This is about my broken heart</p>

                    <p>You think through life a bit differently</p>

                    <p>when you are told how this would all end</p>

                    <p>That's the shock that people live</p>

                    <p>their whole lives re-directing</p>

                    <p>But my time is fixed to the signs</p>

                    <p>my doctors use</p>

                    <p>to understand my heartbeat</p>

                    <p>My heartbeat is proving fatal</p>

                    <p>Anyone should know that</p>

                    <p>living in constant shock</p>

                    <p>is not really shocking any longer</p>

                    <p>It becomes something else entirely</p>

                    <p>My heartbeat was not always erratic</p>

                    <p>At least I can't believe it</p>

                    <p>was always like this</p>

                    <p>It never got so bad that</p>

                    <p>I would lose sensation</p>

                    <p>in my chest and all sense of time</p>

                    <p>It was pretty bad when it first started</p>

                    <p>I mean</p>

                    <p>I think it is</p>

                    <p>a little funny</p>

                    <p>I was singing the song about the horses</p>

                    <p>You must remember it</p>

                    <p>it is a very famous Cantonese melody</p>

                    <p>The song itself is about the potency</p>

                    <p>of strength and</p>

                    <p>courage of these horses</p>

                    <p>They escape the grasp of those that want</p>

                    <p>claim to the land</p>

                    <p>and if it wasn't the land</p>

                    <p>it would be a claim to their bodies</p>

                    <p>& they were forced to</p>

                    <p>always keep moving</p>

                    <p>Prevailing</p>

                    <p>and so on</p>

                    <p>You must remember this</p>

                    <p>song since it is very famous</p>

                    <p>If I sing it for you,</p>

                    <p>you will recognize it</p>

                    <p>When it gets translated</p>

                    <p>from Cantonese into English,</p>

                    <p>it becomes a love song</p>

                    <p>This is the reason why Sin Ya asked</p>

                    <p>me to perform this song at her wedding</p>

                    <p>There's a subliminal quality to it</p>

                    <p>When the audience heard</p>

                    <p>the beginning notes, they</p>

                    <p>clinked their glasses</p>

                    <p>and clapped their hands</p>

                    <p>The simple joy in recognition</p>

                    <p>When I entered the first chorus, I started</p>

                    <p>to feel my chest tighten and lost focus</p>

                    <p>I no longer could control how I was hitting</p>

                    <p>my notes and my vision became blurry</p>

                    <p>In that moment I also</p>

                    <p>realized that the English</p>

                    <p>version of the song got</p>

                    <p>rid of the horses completely</p>

                    <p>There was no longer any trace of</p>

                    <p>them in the words coming from my mouth</p>

                    <p>I was taking part</p>

                    <p>in a violence</p>

                    <p>towards these horses</p>

                    <p>And like a wave that</p>

                    <p>reaches it's crest</p>

                    <p>the pressure in my chest</p>

                    <p>bloomed beyond my control</p>

                    <p>My body went from heat to</p>

                    <p>ice, I shrank into my dress and I</p>

                    <p>could feel the rattle in my heart</p>

                    <p>sending blood up to my ears</p>

                    <p>I couldn't even hear myself singing</p>

                    <p>Later my cousin told me</p>

                    <p>I sang the entire song fine</p>

                    <p>It was only afterwards that</p>

                    <p>I grew pale and collapsed</p>

                    <p>Fainting after singing a</p>

                    <p>love song at a wedding</p>

                    <p>How special</p>

                    <p>An unforgettable song for</p>

                    <p>an unforgettable wedding</p>

                    <p>Sin Ya and her partner</p>

                    <p>are together to this day</p>

                    <p>Did that love song keep</p>

                    <p>them together for this long?</p>

                    <p>I would like to think so</p>

                    <p>Prevailing and so on</p>

                    <p>Prevailing and so on</p>

                    <p>I was diagnosed with arrhythmia</p>

                    <p>shortly after that incident</p>

                    <p>If you are willing to believe it</p>

                    <p>I've been monitoring my heartbeat since</p>

                    <p>It's been two decades now</p>

                    <p>If you didn't know the precariousness</p>

                    <p>of my situation you might</p>

                    <p>assume this type of surveillance</p>

                    <p>of my health is overkill</p>

                    <p>The data of my heart</p>

                    <p>alone is well over 6 terabytes</p>

                    <p>I look for signs of turbulence over</p>

                    <p>the course of weeks, months, years</p>

                    <p>I keep a journal and try to think of even</p>

                    <p>the smallest things that happen each day</p>

                    <p>To try to piece together</p>

                    <p>the root of my condition</p>

                    <p>This is about my broken heart</p>
                </div>


            </Column>
        </ProjectColumns>

    );
}
