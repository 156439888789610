import React, { useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';
import './style.css';
import ScrollToTop from 'Components/ScrollToTop';
import Header from 'Components/Header';
import Header2 from 'Components/Header2';
import NotFound from 'Containers/NotFound';
import Transcript from 'Containers/Transcript';
import Seo from "Components/Seo";

import DalenaTran from '../../work/DalenaTran';
// import Nearrest from '../../work/Nearrest';

ReactGA.initialize("UA-197104025-1");

// const comps = {
//   DalenaTran,
// };

const App = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <div className="app">
        <a className="sr-only" href="#main">Skip to main content</a>
        <Switch>
          <Route path='/' exact>
            <Seo />
            <Header />
            <DalenaTran slug={"/"} name={"Acts in Translation"} />
            {/* no-op */}
          </Route>
          <Route path='/transcript'>
            <Header2 />
            <Transcript />
          </Route>
          {/* <Route path='/nearrest'>
          <Header2 />
            <Nearrest />
          </Route> */}
          {/* Unmatched */}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </>
  );
};

const RoutedApp = () => (
  <Router>
    <App />
  </Router>
);

export default RoutedApp;