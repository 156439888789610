import React from 'react';
import { Link } from 'react-router-dom';
import { heading } from './style.module.scss';
import Bird from './assets/bird.svg';

const Header2 = () => {
    return (
        <div className={heading}>
            <Link to={"/"}><img src={Bird} alt="Scantily drawn bird headed east" /></Link>
        </div>
    );
};

export default Header2;