import React from 'react';
import { Link } from 'react-router-dom';
import { heading, headingWrap } from './style.module.scss';
import Bird from './assets/bird.svg';

const Header2 = () => {
    function refreshPage() {
        window.location.reload();
    }
    return (
        <div className={headingWrap}>
            <div className={heading}>
                <Link onClick={refreshPage} to="/"><img src={Bird} alt="Scantily drawn bird headed east" /></Link>
            </div>
        </div>

    );
};

export default Header2;