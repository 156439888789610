import React from 'react';

import ScrollIndicator from 'Components/ScrollIndicator';

import {
  container
} from './style.module.scss'


const Artist = ({ children, scrollIndicator=false}) => {
  return (
    <div className={container}>
      { scrollIndicator && <ScrollIndicator /> }
      {children}
    </div>
  );
}


export default Artist;
