import React, { useEffect, useRef } from "react";
import "./css/style.css";

import {
  p0,
  p1,
  p2,
  p3,
  audioHeading,
  dtPCover,
  dtText,
  dtButWrapper,
  dtButton,
  dtContribCol,
  dtCredits,
  dtContribWrapper,
  dtInfoCol,
  dtFont,
  soundOn
} from "./style.module.css";


import Artist from "../../Containers/Artist";
import ProjectCover from "../../Components/ProjectCover";
import ProjectColumns, { Column } from "../../Components/ProjectColumns";
import ProjectDescription from "../../Components/ProjectDescription";
import Image from "../../Components/Image";

import DalenaImage3 from "./assets/DalenaTran-3.png";
import PosterImage from "./img/aitPoster.png";
import PosterImageSq from "./img/aitPoster_sq.png";
import Diagram3 from "./assets/diagramv3.png";

import audioJson from "./data/data";
import voiceoversJson from "./data/voiceovers";
import Engines from "./js/engines-utils";

import Seo from "../../Components/Seo";

import { Link } from "react-router-dom";

const rootNodes = [document.body, document.documentElement];

let AIT = function ({ slug, name }) {
  const aitContainer = useRef(null);
  const enginesRef = useRef(null);

  // var w = document.getElementById("AITButton");
  // var x = document.getElementById("AITInfoOverlay");
  // var y = document.getElementById("AITButTextOpen");
  // var z = document.getElementById("AITButTextClose");
  
  const w = useRef(null);
  const x = useRef(null);
  const y = useRef(null);
  const z = useRef(null);
 
  function beginOverlay() {
    if (x.current.style.opacity === "0") {
      w.current.style.zIndex = "9999";
      x.current.style.opacity = "1";
      x.current.style.zIndex = "999";
      y.current.style.display = "none";
      z.current.style.display = "block";
    } else {
      w.current.style.zIndex = "999";
      x.current.style.opacity = "0";
      x.current.style.zIndex = "-999";
      y.current.style.display = "block";
      z.current.style.display = "none";
    }
  }


  function beginProject() {
    enginesRef.current.init();

    aitContainer.current.className = "";
    var video = document.getElementById("AITVidElem");
    video.muted = true;
    video.play();

    rootNodes.forEach((n) => {
      n.style.overflow = "hidden";
      n.style.height = "100%";
    });
  }

  function stopProject() {
    enginesRef.current.halt();

    aitContainer.current.className = "AITHide";
    window.setTimeout(() => {
      var video = document.getElementById("AITVidElem");
      if (video) {
        video.pause();
        rootNodes.forEach((n) => {
          n.style.overflow = "auto";
          n.style.height = "auto";
        });
      }
    }, 800);
  }

  useEffect(() => {
    // if (navigator.userAgent === "ReactSnap") {
    //   return;
    // }


    const engines = (enginesRef.current = new Engines());
    engines.setup(audioJson, voiceoversJson, "AITSub");

    document.getElementById("AITLocA").className = "hidden";
    document.getElementById("AITLocB").className = "hidden";
    // $("#AITVidElem").prop('muted', true);


    var video = document.getElementById("AITVidElem");
    video.muted = true;

    const query = window.location.search;
    if (query.substring(1) === 'frame') {
      console.log(query);
      beginProject();

      var link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", PosterImage.images[PosterImage.images.length - 1].path);
      link.setAttribute("download", "Dalena_Tran.png");
      link.click();
    }


    return function cleanup() {
      stopProject();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Artist>
      <Seo
        title="Acts in Translation"
        description="A web installation about the void that occurs in the process of translation"
        path={slug}
        image={DalenaImage3}
      />
      <ProjectCover className={dtPCover} noShim>
        <Column>
          <Image
            img={Diagram3}
            alt="A scantily drawn diagram of arrows that form two circles with the smaller circle nested inside the larger. A linear flow of arrows moves from one side of the circumference downwards towards a focal point and then travels upwards towards the opposite side of the circumference to create a conical structure."
          />
        </Column>
      </ProjectCover>
      <Column className={dtText}>
        <div className={dtButWrapper}>
            <Link to="#" onClick={beginProject}>
            <div className={dtButton}>
              ACTS IN TRANSLATION
              </div>
            </Link>
        </div>
        <p className={p0}>Two windows having a moment together.</p>
        <p className={p1}>
          A story emerges every hour on the hour in universal coordinated time
          (UTC), marking the passing of time without keeping it.
        </p>
        <p className={p2}>
          Ambient recordings from different cities at separate times are
          procedurally sequenced in relationship to each other.<br /><br />
          <b className={soundOn}>SOUND ON</b>
        </p>


        <p className={p3}>
          {" "}
          Your moment &amp; my moment
          <br />
          are never quite the same.
        </p>
        <div id="AITButton" ref={w} onClick={beginOverlay}>
          <span id="AITButTextOpen" ref={y}>About</span>
          <span id="AITButTextClose" ref={z}>⇜ Back</span>
        </div>
      </Column>
      <div id="AITInfoOverlay" ref ={x} style={{opacity:0}}>
        <div id="AITInfoWrap">
          <ProjectColumns className={dtInfoCol}>
            <Column>
              <ProjectDescription>
                <p>
                  <span className={dtFont}>ACTS IN TRANSLATION</span> is an online, generative moving image and sound installation. On each hour, in Universal Coordinated Time, a story about a broken heart emerges. The unique soundscapes on each page visit are contributions from people and moments around the world. The project was conceived in February 2020 as countries worldwide, gripped by a global pandemic, restricted citizen mobility. These restrictions compelled public urban life into domestic spaces. Consequently, network and communication technologies take on an increasingly integral role in mediating contemporary life. This project serves to archive a distinct passage of everyday life in human history.
                  <br /><br />
                  Transcript & Audio <a className="linky" href="/transcript">⇝</a>

                  {/* <br /><br />
                  <span className={dtFont}>ACTS IN TRANSLATION</span> is a poetic and affectual interplay between the boundaries of language, embodiment, and locality to consider a renewal for listening that does not aim to populate the senses with new things but rather to see things anew. */}
                </p>
              </ProjectDescription>
            </Column>
          </ProjectColumns>
          <ProjectColumns className={audioHeading}>
            <h3>
              Credits</h3>
          </ProjectColumns>
          <ProjectColumns className={dtCredits}>
            <ul aria-labelledby="credits">
              <li>Direction & Concept by Dalena Tran <a className="linky" href="https://dalena.me" target="_blank" rel="noreferrer">⇝</a></li>
              <li>Visuals & Web Design by Dalena Tran</li>
              <li>Technical Support by Hirad Sab <a className="linky" href="https://hiradsab.com" target="_blank" rel="noreferrer">⇝</a></li>
            </ul>
          </ProjectColumns>
          <ProjectColumns className={audioHeading}>
            <h3>
              Audio Contributions</h3>
          </ProjectColumns>
          <ProjectColumns className={dtContribWrapper}>
            <ul aria-labelledby="audio-contribution" className={dtContribCol}>
              <li>Adam Kaye</li>
              <li>Ali Eslami</li>
              <li>André Alves</li>
              <li>Arina Mahmoudian</li>
              <li>Austen Erblat</li>
              <li>Avisha Sab</li>
              <li>Bartek Biernacki</li>
              <li>Berit Gilma</li>
              <li>Clarissa Ribeiro</li>
              <li>Clement Fortin</li>
              <li>Dasul Kim</li>
              <li>Doan Minh Dang</li>
              <li>Donna Ghassemi</li>
              <li>Elton Kuns</li>
              <li>Faraz Dirin</li>
              <li>Hu Rui</li>
            </ul>
            <ul aria-labelledby="audio-contribution" className={dtContribCol}>
              <li>Ilya Bespalov</li>
              <li>Jasmin Xanthos</li>
              <li>Jennifer Steinkamp</li>
              <li>Jimmy Zhi</li>
              <li>Jodi Cheung</li>
              <li>John Goolvart</li>
              <li>Karina Lopez</li>
              <li>Kevin Dichosa</li>
              <li>Lauren Goshinski</li>
              <li>Lauren Lee McCarthy</li>
              <li>Lloyd Galbraith</li>
              <li>Manuel Oliveira</li>
              <li>Mara Lemesany</li>
              <li>Matteo Zamagni</li>
              <li>Matthew Dervenkov</li>
              <li>Matthew Dotson</li>
            </ul>
            <ul aria-labelledby="audio-contribution" className={dtContribCol}>
              <li>Megan Anderson</li>
              <li>Michael Baker</li>
              <li>Miles Peyton</li>
              <li>Mina Tahmouresie</li>
              <li>Mitchell Sang Wang</li>
              <li>Mohamed Allam</li>
              <li>Morehshin Allahyari</li>
              <li>Nathan Seymour</li>
              <li>Omar Castanon</li>
              <li>Pancho Blood</li>
              <li>Pieter Jossa</li>
              <li>Robert Thomas Heppell</li>
              <li>Samah Safiullah</li>
              <li>Sasha Gransjean</li>
              <li>Sebastian Kurtz</li>
              <li>Shriya Ravishankar</li>
            </ul>
          </ProjectColumns>
          <ProjectColumns className={audioHeading}>
            <h3>
              Special Thanks</h3>
          </ProjectColumns>
          <ProjectColumns className={dtCredits}>
            <p>Lauren Lee McCarthy, Chandler McWilliams, Casey Reas, Danny Snelson, Erkki Huhtamo, Cayetano Ferrer, Jennifer Steinkamp, Noa Kaplan, Ana Iwataki, & the DMA 2020 Cohort</p>
          </ProjectColumns>
          <ProjectColumns className={audioHeading}>
            <h3>
              Featured</h3>
          </ProjectColumns>
          <ProjectColumns className={dtCredits}>
            <ul aria-labelledby="credits">
              <li>2020 - NEARREST NEIGHBOR <a className="linky" href="https://near.rest/" target="_blank" rel="noreferrer">⇝</a></li>
              <li>2021 - STRP - COLLECTIVITY<a className="linky" href="http://strp.nl/" target="_blank" rel="noreferrer">⇝</a></li>
              <br />
              <li><i>For exhibition requests, please contact <a className="linky" href="mailto:info@dalena.me" target="_blank" rel="noreferrer">info[at]dalena.me</a></i></li>
            </ul>
          </ProjectColumns>
          <ProjectColumns className={dtCredits}>
            <p>This work has been partially funded by STRP.</p>
          </ProjectColumns>
        </div>
      </div>

      <div id="AITContainer" className="AITHide" ref={aitContainer}>
        <div id="AITButtonBack" onClick={stopProject}>
          <span>⇜ Back</span>
        </div>
        <div id="AITWrapper">
          <div id="AITGridBox">
            <div id="AITVidBox">
              <div id="AITVidWrapper">
                <video
                  id="AITVidElem"
                  autoPlay
                  loop
                  playsInline
                  muted={true}
                  width={960}
                  poster={
                    PosterImage.images[PosterImage.images.length - 1].path
                  }
                >
                  <source
                    type="video/mp4"
                    src="https://users.dma.ucla.edu/~dalena/ait/vid/aith264.mp4"
                  />
                  <source
                    type='video/webm;codecs="vp8,vorbis'
                    src="https://users.dma.ucla.edu/~dalena/ait/vid/aitvp9.webm"
                  />
                </video>
                <video
                  id="AITVidElem_sq"
                  autoPlay
                  loop
                  playsInline
                  muted={true}
                  width={960}
                  poster={
                    PosterImageSq.images[PosterImageSq.images.length - 1].path
                  }
                >
                  <source 
                    type="video/mp4"
                    src="https://users.dma.ucla.edu/~dalena/ait/vid/ait_sqh264.mp4"
                  />
                  <source
                    type='video/webm;codecs="vp8,vorbis'
                    src="https://users.dma.ucla.edu/~dalena/ait/vid/ait_sqvp9.webm"
                  />
                </video>
                <div id="AITTitleBox">
                  <p id="AITSub"></p>
                </div>
              </div>
            </div>
            <div id="AITCityLabA">
              <h3 id="AITLocA"> </h3>
              <br />
            </div>
            <div id="AITCityLabB">
              <h3 id="AITLocB"> </h3>
              <br />
            </div>
            <div id="AITCountDown">
              <p id="AITCountTxt">
                00:<span id="AITCountMins">00</span>:
                <span id="AITCountSecs">00</span>
              </p>
              <p id="AITCountTitle">until the story begins<br />feel free to stay a while</p>
              <br />
            </div>
          </div>
        </div>
        <div id="AITSvgBox"></div>
      </div>
       {/* <ProjectColumns className={audioHeading}>
            <h3 id="audio-contribution">
              Cities</h3>
          </ProjectColumns>
          <ProjectColumns className={dtContribWrapper}>
            <Column>
              <ul aria-labelledby="audio-contribution" className={dtContribCol}>
                <li>Aliso Viejo, USA</li>
                <li>Amsterdam, Netherlands</li>
                <li>Baincthun, France</li>
                <li>Buenos Aires, Argentina</li>
                <li>Cairo, Egypt</li>
                <li>Calgary, Canada</li>
                <li>Chiang Mai, Thailand</li>
                <li>Ensenada, México</li>
                <li>Fort Lauderdale, USA</li>
                <li>Fortaleza, Brazil</li>
                <li>Golden, Colorado</li>
                <li>Graz, Austria</li>
                <li>Guangzhou, China</li>
              </ul>
            </Column>
            <Column>
              <ul aria-labelledby="audio-contribution" className={dtContribCol}>
                <li>Hopkinton, USA</li>
                <li>João Pessoa, Brazil</li>
                <li>London, England</li>
                <li>Los Angeles, USA</li>
                <li>Mafra, Portugal</li>
                <li>Mayfield, England</li>
                <li>Miami, USA</li>
                <li>Milan, Italy</li>
                <li>New Haven, USA</li>
                <li>Philadelphia, USA</li>
                <li>Portland, USA</li>
                <li>Portsmouth, Dominica</li>
                <li>Poznan, Poland</li>
              </ul>
            </Column>
            <Column>
              <ul aria-labelledby="audio-contribution" className={dtContribCol}>
                <li>Riga, Latvia</li>
                <li>Saigon, Vietnam</li>
                <li>Salt Lake City, USA</li>
                <li>Seoul, South Korea</li>
                <li>Shenzhen, China</li>
                <li>Singapore</li>
                <li>Sofia, Bulgaria</li>
                <li>Stockport, England</li>
                <li>Tehran, Iran</li>
                <li>Pancho Blood</li>
                <li>Vancouver, Canada</li>
                <li>Vladivostok, Russia</li>
              </ul>
            </Column>
          </ProjectColumns> */}
    </Artist>
  );
}

export default AIT;

