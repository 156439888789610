import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './style.module.scss';
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

const Player = ({
    url,
}) => (
    <div>
        <AudioPlayer
            src={url}
        />
    </div>
);

export default Player;
